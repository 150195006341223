<template>
  <el-main>
    <el-button style="margin-bottom: 10px" type="primary" size="small" icon="el-icon-plus" @click="addType">添加分类</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="服务分类：">
        <el-input size="small" v-model="name" placeholder="请输入搜索分类"></el-input>
      </el-form-item>
      <el-form-item label="是否显示：">
        <el-select v-model="is_show" size="small">
          <el-option v-for="item in showOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="18px">
        <el-button type="primary" @click="getList" size="small">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }" :tree-props="{ children: '_child', hasChildren: 'hasChildren' }">
      <el-table-column prop="name" label="分类名称" align="center">
        <template v-slot="{ row }">
          <span :style="{ 'font-weight': row._child ? 'bold' : '' }">{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="分类等级" align="center">
        <template v-slot="{ row }">
          <span :style="{ 'font-weight': row._child ? 'bold' : '' }">{{ row.grade }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="分类ID" align="center"></el-table-column>
      <el-table-column prop="sort" label="组内权重" width="120">
        <template #header>
          <div class="edit">
            <span>组内权重</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="small" type="Number" :min="0" @change="editChange(scope.row)" v-model="scope.row.sort"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="picture" label="图片" align="center">
        <template slot-scope="scope">
          <el-image style="width: 60px; height: 60px" :src="scope.row.picture ? scope.row.picture : require('@/assets/default-img.png')"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="是否显示" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_show" :active-value="1" :inactive-value="0" @change="editChange(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加/编辑分类" :visible.sync="showAdd" width="700px">
      <el-form ref="form" :model="form" :rules="rules" class="add-form" label-width="100px">
        <el-form-item class="item" label="分类名称：" prop="name">
          <el-input v-model="form.name" maxlength="10"></el-input>
          <span>分类名称建议12符号/6字以内</span>
        </el-form-item>
        <el-form-item class="item" label="上级分类：">
          <el-select v-model="form.parent_id" :disabled="disabled_type" clearable placeholder="请选择">
            <el-option v-for="item in list" :key="item.id" :disabled="disabled_type" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <span>不选择则默认为一级分类</span>
        </el-form-item>
        <el-form-item class="item" label="组内权重：">
          <el-input type="number" :min="0" v-model="form.sort"></el-input>
          <p>数字越大，排名越靠前；如为空，排序顺序为添加时间</p>
        </el-form-item>
        <el-form-item class="item type-img" label="分类图片 ：">
          <div>
            <ReadyUploadSource @getSource="getTypeImg" :path="form.picture" @removeThis="() => (form.picture = '')"></ReadyUploadSource>
            <p style="margin-top: 10px">一级分类建议图片尺寸506px*200px，二级分类建议图片尺寸216px*216px</p>
          </div>
        </el-form-item>
        <el-form-item class="item" label="是否显示：">
          <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    ReadyUploadSource,
  },
  data() {
    return {
      moveClass_name: '',
      showAdd: !1,
      list: [],
      is_show: 0,
      name: '',
      showOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '否',
        },
        {
          value: 2,
          label: '是',
        },
      ],
      form: {
        id: 0,
        is_show: 1,
        name: '',
        picture: '',
        sort: '',
        parent_id: '',
      },
      disabled_type: !1,
      rules: {
        name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    cancelSearch() {
      this.name = '';
      this.is_show = 0;
      this.getList();
    },
    getTypeImg(val) {
      this.form.picture = val.path;
    },
    addType() {
     
      this.showAdd = !0;
      this.form = {
        id: 0,
        is_show: 1,
        name: '',
        picture: '',
        sort: '',
        parent_id: '',
      };
      this.disabled_type = !1;
    },
    edit(row) {
      let obj = Object.assign({}, row);
      if (!obj.parent_id) obj.parent_id = '';
      obj.grade == '一级' ? (this.disabled_type = !0) : (this.disabled_type = !1);
      delete obj.create_time;
      delete obj.update_time;
      this.form = obj;
      this.showAdd = !0;
    },
    add() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.parent_id && !this.form.picture) return this.$message.warning('请添加分类图片');
          let data = {
            is_show: this.form.is_show,
            picture: this.form.picture,
            sort: this.form.sort || 99,
            parent_id: this.form.parent_id || 0,
            name: this.form.name,
          };
          if (!data.parent_id) return this.$message.warning('最多只能添加三种一级分类！');
          if (this.form.id) data.id = this.form.id;
          let url = data.id ? this.$api.samecity.service.editClassify : this.$api.samecity.service.addClassify;
          this.$axios.post(url, data).then(res => {
            if (res.code == 0) {
              this.showAdd = !1;
              this.getList();
              this.$message.success(`${this.form.id ? '编辑成功' : '添加成功'}`);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getList() {
      this.$axios
        .post(this.$api.samecity.service.classifyList, {
          is_show: this.is_show,
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result;
            console.log(list);
            list.map(item => {
              item.grade = '一级';
              if (item._child) {
                item._child.map(now => (now.grade = '二级'));
              }
            });
            this.list = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    editChange(row) {
      this.$axios
        .post(this.$api.samecity.service.editClassify, {
          id: row.id,
          parent_id: row.parent_id,
          name: row.name,
          picture: row.picture,
          sort: row.sort,
          is_show: row.is_show,
        })
        .then(res => {
          if (res.code == 0) {
            console.log('修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //删除分类
    remove(row) {
      this.$confirm('确认删除此商品分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.samecity.service.delClassify, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.$message.success('删除成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
  .edit {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
    i {
      font-size: 17px;
    }
  }
  .move .item {
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 20px;
  }
  .add-form {
    .el-form-item {
      .el-input,
      .el-select {
        width: 350px;
        margin-right: 10px;
      }
    }
  }
}
/deep/ .el-table__expand-icon--expanded {
  // 这是点击后的旋转角度
  transform: rotate(0deg);
}
/deep/.el-table__expand-icon {
  .el-icon-arrow-right:before {
    //这是收起图标
    content: '\e723';
    font-size: 16px;
  }
}
/deep/ .el-table__expand-icon--expanded {
  .el-icon-arrow-right:before {
    // 这是展开图标
    content: '\e722';
    font-size: 16px;
  }
}
</style>
